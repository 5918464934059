<template>
  <div>
    <!-- The header buttons -->
    <div class="d-flex justify-space-between">
      <!-- Show the go back button -->
      <v-btn
        text
        color="primary"
        :disabled="isRefreshingPosts"
        @click="$router.push({ name: 'CampaignTrackingInfluencers' })"
      >
        <v-icon left>
          arrow_back
        </v-icon>

        Influencers
      </v-btn>

      <div class="d-flex">
        <!-- Show the refresh button -->
        <v-btn
          v-if="overview.canUserWrite"

          text
          class="mr-3"
          color="primary"
          :disabled="isRefreshingPosts"
          @click="$emit('refreshPosts')"
        >
          <v-icon left>
            refresh
          </v-icon>

          Refresh
        </v-btn>

        <!-- Show the stories button -->
        <v-btn
          v-if="overview.model.platforms.includes('instagram')"
          text
          color="primary"
          :disabled="isRefreshingPosts"
          @click="$router.push({ name: 'CampaignTrackingStories' })"
        >
          <v-icon left>
            motion_photos_on
          </v-icon>

          Stories
        </v-btn>

        <!-- Show the analytics button -->
        <v-btn
          text
          color="primary"
          :disabled="isRefreshingPosts"
          @click="$router.push({ name: 'CampaignTrackingAnalytics' })"
        >
          <v-icon left>
            analytics
          </v-icon>

          Analytics
        </v-btn>
      </div>
    </div>

    <!-- If we don't have any posts to show -->
    <div v-if="postsCount === 0">
      <!-- Show a platform selector on the left -->
      <div class="mt-8">
        <!-- Show a platform selector -->
        <platform-selector
          module="campaignTracking"
          :overview="overview"
        />
      </div>

      <!-- Show the animation here -->
      <div style="max-width: 360px" class="mx-auto">
        <lottie-animation
          loop
          file="132293-social-media-post.json"
        />
      </div>

      <div class="text-center">
        Please wait while we fetch your posts.
      </div>

      <div class="text-center mt-6">
        <v-btn
          v-if="overview.canUserWrite"

          depressed
          color="primary"
          @click="$emit('refreshPosts')"
          :disabled="isRefreshingPosts"
          :loading="isRefreshingPosts"
        >
          Fetch Them Now
        </v-btn>
      </div>
    </div>

    <!-- Otherwise -->
    <div
      v-else
      class="pt-12"
    >
      <!-- Show a top row with summary -->
      <div class="d-flex align-center justify-space-between flex-wrap flex-lg-nowrap mb-4">
        <div class="d-flex">
          <!-- show the input for searching terms -->
          <div class="search-input search-input--contain">
            <profile-selector
              :overview="overview"
              label="Search Profile"
              type="search"
              dense
              flat
              solo
            />
          </div>
          <!-- Show the platform selector here -->
          <platform-selector
            module="campaignTracking"
            :overview="overview"
          />
        </div>

        <!-- Show the post stats here -->
        <div
          v-if="!selectedInfluencer || selectedPostsViewTab !== 'grid'"
          class="d-flex mt-4 mt-lg-0"
        >
          <!-- For influencers -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>group</v-icon>
                </v-avatar>

                <span class="mr-2">
                  {{ nFormatter(influencersCount) }}
                </span>
              </v-chip>
            </template>

            <span>
              {{ influencersCount }} influencers
            </span>
          </v-tooltip>

          <!-- For posts -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>collections</v-icon>
                </v-avatar>

                {{ nFormatter(postsCount) }}
              </v-chip>
            </template>

            <span>
              {{ postsCount }} {{ postsCount > 1 ? 'posts' : 'post' }}
            </span>
          </v-tooltip>

          <!-- For likes -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>thumb_up</v-icon>
                </v-avatar>

                {{ nFormatter(likesCount) }}
              </v-chip>
            </template>

            <span>
              {{ likesCount }} Likes
            </span>
          </v-tooltip>

          <!-- For views -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>play_circle</v-icon>
                </v-avatar>

                {{ nFormatter(viewsCount) }}
              </v-chip>
            </template>

            <span>
              {{ viewsCount }} Views
            </span>
          </v-tooltip>

          <!-- For comments -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>chat_bubble</v-icon>
                </v-avatar>

                {{ nFormatter(commentsCount) }}
              </v-chip>
            </template>

            <span>
              {{ commentsCount }} Comments
            </span>
          </v-tooltip>

          <!-- For shares -->
          <v-tooltip
            v-if="sharesCount > 0"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>send</v-icon>
                </v-avatar>

                {{ nFormatter(sharesCount) }}
              </v-chip>
            </template>

            <span>
              {{ sharesCount }} Shares
            </span>
          </v-tooltip>

          <!-- For saves -->
          <v-tooltip
            v-if="savesCount > 0"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>bookmark</v-icon>
                </v-avatar>

                {{ nFormatter(savesCount) }}
              </v-chip>
            </template>

            <span>
              {{ savesCount }} Saves
            </span>
          </v-tooltip>

          <!-- For Engagement Rate -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>bar_chart</v-icon>
                </v-avatar>

                {{ (averageEngagement * 100).toFixed(2) }}%
              </v-chip>
            </template>

            <span>
              {{ (averageEngagement * 100).toFixed(2) }}% Average Engagement Rate
            </span>
          </v-tooltip>
        </div>
      </div>

      <!-- Show a divider in between -->
      <v-divider class="mb-3" />

      <!-- If we have  -->
      <template v-if="response && response.data">
        <div
          v-if="response.total === 0"
          class="text-center mt-4"
        >
          {{ selectedInfluencer ? 'No posts found for this influencer' : 'There are no posts, please try refreshing.' }}
        </div>

        <!-- Otherwise -->
        <div v-else>
          <div class="d-flex justify-space-between align-center">
            <!-- Option to short posts -->
            <div class="contain-select-width">
              <v-select
                v-if="selectedPostsViewTab === 'grid'"
                v-model="sortBy"
                :items="sortOptions"
                label="Sort By"
                color="primary"
                hide-details
                outlined
                dense
              ></v-select>
            </div>

            <v-tabs
              right
              :value="selectedPostsViewTab"
              @change="(v) => $emit('selectedPostsViewTabChange', v)"
              background-color="transparent"
            >
              <v-tab href="#table">
                <v-icon left>
                  table_chart
                </v-icon>

                Table
              </v-tab>

              <v-tab href="#grid">
                <v-icon left>
                  grid_view
                </v-icon>

                Grid
              </v-tab>
            </v-tabs>
          </div>

          <v-tabs-items
            :value="selectedPostsViewTab"
            @change="(v) => $emit('selectedPostsViewTabChange', v)"
            class="mt-6 transparent"
          >
            <!-- Show the data table -->
            <v-tab-item value="table">
              <posts-table
                :should-show-actions="true"
                :overview="overview"
              />
            </v-tab-item>

            <!-- Show the grid items -->
            <v-tab-item value="grid">
              <v-row>
                <v-col
                  v-for="item in response.data"
                  :key="'post-' + item.id"
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <!-- Use a common posts component -->
                  <post :data="item" />
                </v-col>
              </v-row>

              <div
                id="intersect-detector"
                ref="intersectDetector"
                v-intersect="handleIntersect"
              ></div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// Import children components
const Post = () => import(/* webpackChunkName: "crm-post" */ "@/components/crm/Post.vue")
const PostsTable = () => import(/* webpackChunkName: "crm-posts-table" */ "@/components/crm/PostsTable.vue")
const ProfileSelector = () => import(/* webpackChunkName: "crm-profile-selector" */ "@/components/crm/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "crm-platform-selector" */ "@/components/crm/PlatformSelector.vue")

const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Export the SFC
export default {
  // Name of the component
  name: "CampaignTrackingPosts",

  // Register children components
  components: {
    Post,
    PostsTable,
    ProfileSelector,
    PlatformSelector,
    LottieAnimation,
  },

  props: {
    overview: {
      type: Object,
      required: true
    },

    categories: {
      type: Array,
      required: true
    },

    isRefreshingPosts: {
      type: Boolean,
      required: false,
      default: false
    },

    selectedPostsViewTab: {
      type: String,
      required: true,
    }
  },

  // Define local data variables
  data: () => ({
    // Sort filter values
    sortBy: "most_liked",

    // The dropdown option values
    sortOptions: [
      {
        text: "Recent",
        value: "recent"
      },
      {
        text: "Oldest",
        value: "oldest"
      },
      {
        text: "Least Liked",
        value: "least_liked"
      },
      {
        text: "Most Liked",
        value: "most_liked"
      },
      {
        text: "Least Commented",
        value: "least_commented"
      },
      {
        text: "Most Commented",
        value: "most_commented"
      }
    ],

    // The key value pair for sort options
    sortMap: {
      recent: {
        sortBy: "posted_at",
        sortOrder: "desc"
      },

      oldest: {
        sortBy: "posted_at",
        sortOrder: "asc"
      },

      least_liked: {
        sortBy: "likes",
        sortOrder: "asc"
      },

      most_liked: {
        sortBy: "likes",
        sortOrder: "desc"
      },

      least_commented: {
        sortBy: "comments",
        sortOrder: "asc"
      },

      most_commented: {
        sortBy: "comments",
        sortOrder: "desc"
      }
    },

    // Whether or not a request is being made currently
    isMakingRequest: false
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get the total number of influencers here
     *
     * @returns {Number}
     */
    influencersCount() {
      return this.categories[0].accounts
    },

    /**
     * Get the total number of posts here
     *
     * @returns {Number}
     */
    postsCount() {
      return this.categories[0].posts
    },

    /**
     * Get the number of total likes value
     *
     * @returns {Number}
     */
    likesCount() {
      return this.categories[0].likes
    },

    /**
     * Get the number of total views value
     *
     * @returns {Number}
     */
    viewsCount() {
      return this.categories[0].views
    },

    /**
     * Get the number of total comments value
     *
     * @returns {Number}
     */
    commentsCount() {
      return this.categories[0].comments
    },

    /**
     * Get the number of total shares value
     *
     * @returns {Number}
     */
    sharesCount() {
      return this.categories[0].shares
    },

    /**
     * Get the number of total saves value
     *
     * @returns {Number}
     */
    savesCount() {
      return this.categories[0].saves
    },

    /**
     * Get the average engagement rate value
     *
     * @returns {Number}
     */
    averageEngagement() {
      return this.categories[0].engagement_rate
    },

    /**
     * Get the query object for the influencers
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["campaignTracking/postsGridQueryById"](this.overview.model.id)
    },

    /**
     * Get the current page number
     *
     * @returns {Number}
     */
    currentPage: {
      get() {
        return this.query.pagination.page
      },

      set(value) {
        this.$store.dispatch("campaignTracking/updatePostsGridQuery", {
          id: this.overview.model.id,
          query: {
            ...this.query,
            pagination: {
              ...this.query.pagination,
              page: value
            }
          }
        })
      }
    },

    /**
     * Get the current per page value
     *
     * @returns {Number}
     */
    perPage: {
      get() {
        return this.query.pagination.perPage
      },

      set(value) {
        this.$store.dispatch("campaignTracking/updatePostsGridQuery", {
          id: this.overview.model.id,
          query: {
            ...this.query,
            pagination: {
              ...this.query.pagination,
              perPage: value
            }
          }
        })
      }
    },

    /**
     * Get the response object for the influencers
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters["campaignTracking/postsGridResponseById"](this.overview.model.id)
    },

    /**
     * Get the selected platform
     *
     * @returns {String}
     */
    selectedPlatform() {
      return this.$store.getters["campaignTracking/selectedPlatformById"](this.overview.model.id)
    },

    /**
     * Get the selected influencer
     *
     * @returns {Object}
     */
    selectedInfluencer() {
      return this.$store.getters["campaignTracking/selectedInfluencerById"](this.overview.model.id)
    }
  },

  // Set watchers for local data
  watch: {
    /**
     * If the selected platform changes
     *
     * @returns {void}
     */
    selectedPlatform: {
      handler() {
        // Ask to fetch the data from the server again
        this.fetchGridPosts()
      }
    },

    /**
     * If the selected influencer changes
     *
     * @returns {void}
     */
    selectedInfluencer: {
      handler() {
        // Ask to fetch the data from the server again
        this.fetchGridPosts()
      }
    },

    /**
     * If the sort by value changes
     *
     * @returns {void}
     */
    sortBy() {
      // Reset the pagination
      this.currentPage = 1

      // Call the search function
      this.fetchGridPosts()
    },
  },

  // Define local method functions
  methods: {
    /**
     * Fetch the grid posts
     *
     * @returns {void}
     */
    async fetchGridPosts() {
      // If a request is being made
      if (this.isMakingRequest) return false

      // Show a loader
      const loaderId = Symbol("CampaignTracking/fetchPostsGrid")
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Get the influencer object from API
      try {
        // Define the query params
        const queryParams = new window.URLSearchParams(
          Object.entries({
            page: this.query.pagination.page,
            per_page: this.query.pagination.perPage,

            platform: this.selectedPlatform,
            influencer: this.selectedInfluencer ? this.selectedInfluencer.id : null,

            ...this.sortMap[this.sortBy]
          }).filter(([key, value]) => Boolean(value))
        )

        // Use helper function
        const response = await axios(`/api/campaign-tracking/${this.overview.model.id}/posts?${queryParams}`)

        // Update the response object
        this.$store.dispatch("campaignTracking/updatePostsGridResponse", {
          id: this.overview.model.id,
          response: response.data
        })
      }
      // Catch any error
      catch (error) {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Failed to fetch posts!" })

        // Log it
        logger({ type: "CampaignTracking/fetchGridPosts Error", error })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },

    /**
     * Whether or not the user has scrolled past the intersection limit
     *
     * @param {Object} entries
     * @param {Object} observer
     * @param {Boolean} isIntersecting
     */
    handleIntersect(entries, observer, isIntersecting) {
      // Stop execution if user didn't scroll down
      if (!isIntersecting) return false

      // If a request is being made, don't continue
      if (this.isMakingRequest) return false

      // If there's no data possible, don't continue
      if (this.response.data.length >= this.response.total) return false

      // Otherwise, call the search function
      this.currentPage++
      this.fetchGridPosts()
    },

    /**
     * Handle the refresh event
     *
     * @returns {void}
     */
    handleRefreshEvent() {
      // Use helper function
      this.fetchGridPosts()

      // Call to refresh overview
      this.$emit("refreshData")
    }
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  async created() {
    // If the query value is not set
    if (!this.query) {
      // Set the default query value
      this.$store.dispatch("campaignTracking/updatePostsGridQuery", {
        id: this.overview.model.id,
        query: {
          pagination: {
            page: 1,
            perPage: 4
          },

          sortBy: "most_liked"
        }
      })
    }

    // If there's a selected user
    if (this.selectedInfluencer) {
      // Show the grid view
      this.$emit("selectedPostsViewTabChange", "grid")
    }

    // Fetch the grid posts
    this.fetchGridPosts()

    // Add event listener for refresh
    window.addEventListener("campaignTracking:refreshPosts", this.handleRefreshEvent)
  },

  /**
   * As soon as the component is to be destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Remove event listener for refresh
    window.removeEventListener("campaignTracking:refreshPosts", this.handleRefreshEvent)
  }
}
</script>

<style lang="stylus" scoped>
.search-input
    width 100%

    &--contain
      @media (min-width 960px)
        width 14em
</style>
